@import '../index.scss';

.sign-up-form {
  box-shadow: 0 0 3px 0 $brand-black-shadow;
  background-color: $brand-white;
  padding: 32px 64px;
  margin: 0 auto 0;
  text-align: center;
  text-size-adjust: 14px;
  border-radius: 0.5rem;
  width: 28rem;

  .freetrialactionbtn {
    padding: 13px !important;
    width: 45% !important;
    height: auto !important;
    max-width: 150px;
    border-radius: 5px !important;
  }

  .talktosales{
    max-width: 175px;
    min-width: 162px;
  }

  .viewpricebtn {
    border-color: $brand-blue;
    border-width: 2px;
    color: $brand-blue;
    margin-top: -5px;
    max-width: 175px;
    min-width: 162px;
  }

  .freetrialerror {
    color: #7b7b7b;
    margin-top: 20px;
    font-size: 12px;

    a{
      color: $brand-blue;
    }
  }

  .freetrialtitle {
    font-weight: 600;
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 18px;
  }

  .mat-error {
    text-align: right;
  }

  .input-box {
    border-radius: 0px;
    padding: 10px;
    margin: 10px 0;
    width: 80%;
    border: outset;
    outline: none;
  }
  button {
    text-transform: none;
  }
  .send-again{
    display: inline;
    width: auto;
    font-size: 12px;
    padding: 0;
    text-transform: none;
  }
  .submit-btn {
    margin: 30px 0 10px 0;
  }
  .submit-google-btn {
    background-color: $brand-white;
    border: 1px solid $brand-border;
  }
  span {
    color: $brand-blue;
  }
  hr {
    margin: 25px 0;
    width: 100%;
    color: #868889;
    background-color: #868889;
  }
  .or {
    background: $brand-white;
    width: 30px;
    margin: -38px auto 10px;
    color: #868889;
  }
  img {
    width: 100px;
  }

  & p.link {
    color: black;
    margin-top: 0px;
    font-size: 12px;

    a, a:active{
      color: $brand-blue;
    }
  }

  .privacy-policy {
    font-size: 12px;
    margin: 1rem -10px 0rem;
    color: #767676;
    
    a, span {
      color: #767676;
      text-decoration: underline;
    }
  }

  h2 {
    font-size: 18px;
  }


  & p {
    &.error-user {
      margin-top: 0.5rem;
    }
  }

  .error-user {
    color: #cc0000;
    text-align: left;
    margin: 0;
    font-size: 12px;

    & + ul {
      margin: 0;
      padding-left: 1.4rem;
    }
  }

  .microsoft-login {
    width: 100%;
    border-color: #016ff6;
    border-width: 2px !important;
    color: black;
    margin-top: 20px;

    & img {
      width: 20px;

    }
  }
}

.totp-error {
  box-shadow: 0 0 3px 0 $brand-black-shadow;
  background-color: $brand-white;
  padding: 32px 64px;
  margin: 0 auto 0;
  text-align: center;
  text-size-adjust: 14px;
  border-radius: 0.5rem;
  width: 30rem;

  & section {

    & h2 {
      font-size: 1.125rem;
      font-weight: 500;
      font-family: 'Roboto-Medium', Roboto, sans-serif;

      & img {
        vertical-align: text-bottom;
      }
    }

    & .link {
      font-size: 0.8rem;
      margin-top: 1.5rem;
    }
    
    & .download-qr {
      display: flex;
      flex-wrap: nowrap;
      
      & > * {
        flex: 1;
      }

      & p {
        font-family: 'Roboto-Medium', sans-serif;
        font-size: 0.925rem;
        margin-bottom: 0.4rem;
      }

      & img {
        width: 90%;
      }
    }

  }

}

@media screen and (max-width: 767px){
  .sign-up-form{
    width: 100%;
    max-width: 100%;
  }
}
